/**
 * Move related prodcts.
 */

import {PureComponent, Suspense} from 'react';

import ContentWrapper from 'SourceComponent/ContentWrapper';
import NoMatchHandler from 'SourceRoute/NoMatchHandler';
import {LinkedProductType} from 'SourceStore/LinkedProducts/LinkedProducts.type';
import {ReactElement} from 'SourceType/Common.type';

import './ProductPage.style';

import {
    ProductLinks,
    ProductPageComponent as SourceProductPageComponent
} from 'SourceRoute/ProductPage/ProductPage.component'
import {lowPriorityLazy} from "Util/Request/LowPriorityRender";
import ProductGallery from "Component/ProductGallery";
import TextPlaceholder from "Component/TextPlaceholder";
import Loader from "SourceComponent//Loader";
import {ProductPageTabs} from 'SourceRoute/ProductPage/ProductPage.config';
import {ProductPageTab} from 'SourceRoute/ProductPage/ProductPage.type';

export const ProductActions = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "overlays" */
    'Component/ProductActions'
    ));

/** @namespace Route/ProductPage/Component */
export class ProductPageComponent extends SourceProductPageComponent {
    tabMap: Record<ProductPageTabs, ProductPageTab> = {
        [ProductPageTabs.INFORMATION]: {
            name: __('About'),
            shouldTabRender: (): boolean => {
                const {isInformationTabEmpty} = this.props;

                return !isInformationTabEmpty;
            },
            render: (key: string): ReactElement => this.renderProductInformationTab(key),
        },
        [ProductPageTabs.ATTRIBUTES]: {
            name: __('Details'),
            shouldTabRender: (): boolean => {
                const {isAttributesTabEmpty} = this.props;
                //Maskinia - dont show Details for bundle products.
                if (this.props.activeProduct.type_id == 'bundle') {
                    return false;
                }
                return !isAttributesTabEmpty;
            },
            render: (key: string): ReactElement => this.renderProductAttributesTab(key),
        },
        [ProductPageTabs.REVIEWS]: {
            name: __('Reviews'),
            // Return true since we always show 'Add review' button
            shouldTabRender: (): boolean => {
                const {areReviewsEnabled} = this.props;

                return areReviewsEnabled;
            },
            render: (key: string): ReactElement => this.renderProductReviewsTab(key),
        },
    };

    renderRelatedProducts(): ReactElement {
        const {
            areDetailsLoaded,
        } = this.props;

        return (
            <>
                <Suspense fallback={null}>
                    <ProductLinks
                        linkType={LinkedProductType.RELATED}
                        title={__('Recommended for you')}
                        areDetailsLoaded={areDetailsLoaded}
                    />
                </Suspense>
            </>
        );
    }

    renderAdditionalSections(): ReactElement {
        const {
            areDetailsLoaded,
        } = this.props;

        return (
            <>
                {this.renderProductTabs()}
                <Suspense fallback={null}>
                    <ProductLinks
                        linkType={LinkedProductType.UPSELL}
                        title={__('You might also like')}
                        areDetailsLoaded={areDetailsLoaded}
                    />
                </Suspense>
            </>
        );
    }

    //Maskinia - add logic for adding Maskinia Approved icon.
    renderApprovedText(): ReactElement {
        if (!this.props.activeProduct.id) {
            return null;
        }
        ///@ts-ignore
        let maskiniaApproved = '';
        const {activeProduct: {attributes}} = this.props;
        //@ts-ignore
        if (attributes.highlight) {
            //@ts-ignore
            maskiniaApproved = attributes.highlight.attribute_options[attributes.highlight.attribute_value].label;
        }

        if (maskiniaApproved == '') {
            return null;
        }

        //Add translation to the highlight code.
        switch (maskiniaApproved.toLowerCase()) {
            case 'fynd':
                return (<div className="MaskiniaApprovedText">
                    <h2>{__('This is a bargain item with reservation for final sale.')}</h2>
                </div>);
            default:
                return null;
        }
    }

    renderProductPageContent(): ReactElement {
        const {
            areDetailsLoaded,
            activeProduct,
            useEmptyGallerySwitcher,
            isVariant,
        } = this.props;

        return (
            <>
                <ProductGallery
                    product={activeProduct}
                    areDetailsLoaded={areDetailsLoaded}
                    isWithEmptySwitcher={useEmptyGallerySwitcher}
                    showLoader={isVariant}
                />
                <div>
                    {this.renderApprovedText()}
                    {this.renderProductActions()}
                </div>
            </>
        );
    }


    render(): ReactElement {
        //Maskinia move related products
        return (
            <NoMatchHandler>
                <main
                    block="ProductPage"
                    aria-label="Product page"
                    itemScope
                    itemType="http://schema.org/Product"
                >
                    <ContentWrapper
                        wrapperMix={{block: 'ProductPage', elem: 'Wrapper'}}
                        label={__('Main product details')}
                    >
                        {this.renderProductPageContent()}
                        {this.renderRelatedProducts()}
                    </ContentWrapper>
                    {this.renderAdditionalSections()}
                    {this.renderReviewPopup()}
                </main>
            </NoMatchHandler>
        );
    }

    //Maskinia - mobile, show product name.
    renderProductActions() {
        const {
            getLink,
            dataSource,
            areDetailsLoaded,
            setActiveProduct,
            parameters,
            isMobile,
        } = this.props;

        return (
            <div block="ProductPage" elem="ProductActions">
                {this.renderProductDesktopMainData()}
                <Suspense fallback={this.renderProductActionsPlaceholder()}>
                    <ProductActions
                        getLink={getLink}
                        product={dataSource}
                        parameters={parameters}
                        areDetailsLoaded={areDetailsLoaded}
                        setActiveProduct={setActiveProduct}
                    />
                </Suspense>
            </div>
        );
    }

    renderProductBrand(): ReactElement {
        const {
            dataSource: {
                attributes: {brand} = {},
            },
        } = this.props;

        if (!brand) {
            return null;
        }
        const brandLabel = brand.attribute_options[brand.attribute_value].label;
        return (
            <>
                <h4 block="ProductPage" elem="Brand" itemProp="brand">
                    <TextPlaceholder content={brandLabel}/>
                </h4>
            </>
        );
    }
}

export default ProductPageComponent;
