/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {connect} from 'react-redux';
import {RootState} from 'SourceUtil/Store/Store.type';
import {
    ProductGalleryComponentContainerPropKeys,
    ProductGalleryComponentProps,
    ProductGalleryContainerFunctions,
    ProductGalleryContainerMapDispatchProps,
    ProductGalleryContainerMapStateProps,
} from './ProductGallery.type';


import {
    ProductGalleryContainer as SourceProductGalleryContainer
} from 'SourceComponent/ProductGallery/ProductGallery.container';

/** @namespace Component/ProductGallery/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): ProductGalleryContainerMapStateProps => ({
    isMobile: state.ConfigReducer.device.isMobile,
});

/** @namespace Component/ProductGallery/Container/mapDispatchToProps */
export const mapDispatchToProps = (): ProductGalleryContainerMapDispatchProps => ({});

/** @namespace Component/ProductGallery/Container */
export class ProductGalleryContainer extends SourceProductGalleryContainer {

//Maskinia - add attribute to handle icon for Maskinia approved.
    // @ts-ignore
    containerProps(): Pick<ProductGalleryComponentProps, ProductGalleryComponentContainerPropKeys> {
        const {activeImage, isZoomEnabled, isImageZoomPopupActive} = this.state;
        const {
            product: {id},
            isMobile,
            isWithEmptySwitcher,
            showLoader,
            product: {attributes}
        } = this.props;

        return {
            gallery: this.getGalleryPictures(),
            productName: this._getProductName(),
            activeImage,
            isZoomEnabled,
            productId: id,
            isMobile,
            isImageZoomPopupActive,
            sliderRef: this.sliderRef,
            isWithEmptySwitcher,
            showLoader,
            attributes,
        };
    }


}

export default connect(mapStateToProps, mapDispatchToProps)(ProductGalleryContainer);
