/* eslint-disable no-magic-numbers */

/**
 * Specialhandling for removing decimal 0000 on some attributes.
 */

import {
    ComponentType,
    createRef,
    PureComponent,
    ReactNode,
    Suspense,
} from 'react';

import {ReactElement} from 'Type/Common.type';

import {
    ProductGalleryComponent as SourceProductGalleryComponent
} from 'SourceComponent/ProductGallery/ProductGallery.component'

import {lowPriorityLazy} from 'SourceUtil/Request/LowPriorityRender';
import fynd from 'Style/icons/fynd.svg';
import approved from 'Style/icons/approved.svg';
import campaign from 'Style/icons/kampanj.svg';
import blackweekend from 'Style/icons/blackweekend.svg';
import begagnat from 'Style/icons/begagnat.svg';

export const VideoPopup = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-overlays" */
    'Component/VideoPopup'
    ));

/** @namespace Component/ProductAttributeValue/Component */
export class ProductGalleryComponent extends SourceProductGalleryComponent {

    renderApprovedIcon(): ReactElement {
        if (!this.props.productId) {
            return null;
        }

        let maskiniaApproved = '';
        //@ts-ignore
        const {attributes} = this.props;
        //@ts-ignore
        if (attributes.highlight) {
            //@ts-ignore
            maskiniaApproved = attributes.highlight.attribute_options[attributes.highlight.attribute_value].label;
        }

        if (maskiniaApproved == '') {
            return null;
        }

        switch (maskiniaApproved.toLowerCase()) {
            case 'fynd':
                return (<div className="MaskiniaApprovedIcon">
                    <img src={fynd}/>
                </div>);
            case 'approved':
                return (<div className="MaskiniaApprovedIcon">
                    <img src={approved}/>
                </div>);
            case 'kampanj':
                return (<div className="MaskiniaApprovedIcon">
                    <img src={campaign}/>
                </div>);
            case 'blackweekend':
                return (<div className="MaskiniaApprovedIcon">
                    <img src={blackweekend}/>
                </div>);
            case 'beg':
                return (<div className="MaskiniaApprovedIcon">
                    <img src={begagnat}/>
                </div>);
            default:
                return (
                    <div className="MaskiniaApproved">
                        <h2>{maskiniaApproved}</h2>
                    </div>
                );
        }
    }


    render(): ReactElement {
        return (
            <div block="ProductGallery" ref={this.galleryRef}>
                {this.renderApprovedIcon()}
                {this.renderSlider()}
                {this.renderAdditionalPictures()}
                <Suspense fallback={null}>
                    <VideoPopup/>
                </Suspense>
            </div>
        );
    }
}

export default ProductGalleryComponent;
