/**
 * Maskinia: set heigt to 70% of image widht when no image found.
 */

import CSS from 'Util/CSS';

import './TranslateOnCursorMove.style';

import {
    TranslateOnCursorMoveComponent as SourceTranslateOnCursorMoveComponent
} from 'SourceComponent/TranslateOnCursorMove/TranslateOnCursorMove.component';

/** @namespace Component/TranslateOnCursorMove/Component */
export class TranslateOnCursorMoveComponent extends SourceTranslateOnCursorMoveComponent {

    handleLoad(): void {
        const {
            activeImageId,
            itemSelector,
            targetSelector,
        } = this.props;

        const targets = this.ref.current?.querySelectorAll<HTMLElement>(itemSelector);
        const target = targets?.[activeImageId]?.querySelector<HTMLElement>(targetSelector);

        if (!target) {
            return;
        }

        //Maskinia
        let innerHeight = target.getBoundingClientRect().height;
        const {height: wrapperHeight = 0} = this.ref.current?.getBoundingClientRect() || {};
        //Maskinia - hardcoded.
        if (innerHeight == 0) {
            innerHeight = wrapperHeight;
        }
        let translate = (wrapperHeight - innerHeight) / 2;

        //Maskinia - If transform is 0, change to 20px to add som space above.
        if (translate == 0) {
            translate = 20;
        }
        // style set directly (not via `setVariable`) as different translate Y values have to be applied at the same time
        // (as 2 slider images are shown simultaneously when navigating to next/previous image)
        target.style.transform = `translateY(${translate}px)`;
        //Maskinia - Add this to make picture not that big.
        target.style.height = '90%';
        target.style.maxHeight = '800px';
        CSS.setVariable(this.ref, 'imageOpacity', '1');
    }

}

export default TranslateOnCursorMoveComponent;
